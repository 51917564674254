import { Button, Col, Container, Form, Row, Table } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { useEffect, useState } from "react";
// import { height, width } from "@mui/system";
import axios from "axios";
// import styled from "@emotion/styled";
// import { colors } from "@mui/material";
import { Link } from "react-router-dom";
import SidebarProfile from "../SidebarProfile";
import '../../styles/profile.css' 

import { BASE_URL, tableCustomStyles } from "../../App";
import ScrollTopButton from "../ScrollTop";
import ReactPaginate from "react-paginate";
import swal from "sweetalert";
// import '../styles/table.css'

function ProfileAttendance({style}) {


    const [attendance , setAttendance] = useState([]);
    const [walletBalance, setWalletBalance] = useState(0);
    const [code, setCode] = useState("");
    const [group , setGroup] = useState("")
    const [errors , setErrors] = useState("")
    const [isLoading , setIsLoading] = useState(true);


    const token = localStorage.getItem('token');
    const axiosInstance = axios.create({
      baseURL: `${BASE_URL}`,
      headers: {
          'Content-Type': 'application/json',
          "ngrok-skip-browser-warning": "any",
          Authorization: `Bearer ${token}`,
      },
  });


        // الريكويست الخاص بعرض الأكواد المشحونة
          const getcodes = async () => {
              try {
                const response = await axiosInstance.get('/api/myAbsence');
                  // setWalletBalance(response.data.message);
                  setAttendance(response.data.data)
                  // setGroup(response.data.data.map((g) => g.group))
                  setIsLoading(false)
                  const data = response.data.data;
                          return data;
              } catch (error) {
                setIsLoading(false)

              }
          }
          useEffect (() =>{
            getcodes();
          }, [])

console.log(attendance);

function formatDate(dateString) {
  try {
    const date = new Date(dateString);
    const options = {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', timeZone: 'UTC', numberingSystem: 'arab'};
    const formatter = new Intl.DateTimeFormat('ar-EG', options);
    const formattedDate = formatter.format(date);
    return formattedDate;
  } catch (e) {
    // console.error('Error formatting date', e);
    return '';
  }
}




  const [pageNumber, setPageNumber] = useState(0);
  const itemsPerPage = 10;
  const pagesVisited = pageNumber * itemsPerPage;

  const pageCount = Math.ceil(attendance.length / itemsPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };
  


  const displayCodes = Object.values(attendance) 
  .slice(pagesVisited, pagesVisited + itemsPerPage)
  .map((attendance, index) => (
    <tr key={attendance.id}>
    <td>{index + 1}</td>
    <td>{attendance.id}</td>
    <td> {attendance.name } </td>
    <td> {attendance.group?.name ? attendance.group?.name : ' غياب'} </td>

    <td key={attendance.id} style={{
                              backgroundColor: attendance.is_enrolled === 1 ? "#198754" : "#dc3545"
                            }}>{attendance.is_enrolled === 1 ? "Enrolled" : "Absence"}</td> 
  </tr>
  ));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  

  return (
    <>
     {
          isLoading
          ?
          <div className={`main-loading ${style ? 'dark-mode' : ''}`}>
          <div className="loading-course"> 
         <h2>  .... جاري تحميل بيانات الغياب والحضور</h2>
         </div>; 
         </div>
         :
         <div className={`profile d-flex ${style ? 'dark-mode' : ''}`}>
               <SidebarProfile />
                  <div className={`content-profile  ${style ? 'dark-mode' : ''}`}>
                    <Row className="mb-4">
                      <Col lg={12}>
                        <div className="title-main">
                          <h2>  بيانات  <span>  غيابك وحضورك </span> </h2>
                        </div>
                      </Col>
                    </Row>
                    <Row className="mb-4">
                      <Col lg={12}>
                {
                  attendance
                  ?
                                <div className="main-table">
                                <Table responsive  bordered >
              <thead>
              <tr>
              <th>#</th>
              <th>   التسلسل</th>
              <th>   اسم الكورس </th>
              <th >الجروب  </th>
              <th>    الغياب </th>
              </tr>
              </thead>
              <tbody >



              {displayCodes}

              </tbody>
              </Table>
              <ReactPaginate
              previousLabel={'السابق'}
              nextLabel={'التالي'}
              pageCount={pageCount}
              onPageChange={changePage}
              containerClassName={'pagination'}
              previousLinkClassName={'page-link'}
              nextLinkClassName={'page-link'}
              disabledClassName={'disabled'}
              activeClassName={'active'}
              />
              </div>
                :
                <div className="non-courses mb-5  mt-5 ">
                <h2>  مفيش حصص حضرتها لحد دلوقتي  <span className="d-block mt-1">    سيعرض الغياب والحضور مع شراء الكورسات </span> </h2>
              </div>

                }
                      </Col>
                      
                    </Row>
                    <Row></Row>
                    
                    
                  </div>
              </div>
     }

         
        < ScrollTopButton />

    </>
  );
}

export default ProfileAttendance;


